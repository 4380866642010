.font-size {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .label {
    color: #4a535b;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: normal;
  }

  .diy-input-container {
    width: auto;
    padding: inherit;
  }
}

.quill {
  max-width: 400px;
  width: 400px;
}

.ql-editor {
  white-space: normal !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
